window.dataLayer = window.dataLayer || [];

window.track_event = (eventName, properties = {}) => {
  window.dataLayer.push({
    event: eventName,
    ...properties,
  });
};

const dataUsedOnGTMInit = () => {
  const optimizelyRedirectInfo = window.optimizely
    ?.get?.('state')
    ?.getRedirectInfo?.();
  const preRedirectReferrerOrCurrentReferrer = optimizelyRedirectInfo
    ? optimizelyRedirectInfo.referrer
    : document.referrer;

  return {
    donation_interface: !!window.StripeTerminal ? 'lab_kiosk' : null,
    gpc_enabled: !!navigator.globalPrivacyControl,
    real_referrer: preRedirectReferrerOrCurrentReferrer,
    redirected_by_optimizely: !!optimizelyRedirectInfo,
    user_id: window.userId || undefined,
  };
};

window.dataLayer.push(dataUsedOnGTMInit());
window.dataLayer.push({ event: 'gtm.js', 'gtm.start': new Date().getTime() });

const scriptTarget = document.getElementsByTagName('script')[0];
const script = document.createElement('script');
script.async = true;
script.src = window.gtmScriptUrl;
scriptTarget.parentNode.insertBefore(script, scriptTarget);
