import store from '~/javascripts/store.js';
import {
  amountInputSubscriptionPeriod as amountInputSubscriptionPeriodOption,
  minimumAmount as minimumAmountOption,
} from '~/javascripts/utils/subscriptionOptions';
import {
  amountWithPrecisionAsRequired,
  amountWithPrecisionAsRequiredAndComma,
} from '~/javascripts/utils/numberFormatter';
import { debounce, find, includes, isEmpty } from 'lodash';
import { experiments } from '~/javascripts/utils/optimizeleyInfo';
import { makeAutoObservable } from 'mobx';
import { pauseForSpecs } from '~/javascripts/utils/testing';
import {
  sessionUrlParamsMetadataFields,
  urlParamsMetadataFields,
} from '~/javascripts/utils/params';

export default class {
  _amount = 0;
  disableAddressCollection = false;
  amountInputShouldFocus = false;
  amountStepTextBlock1xDonation = '';
  amountStepTextBlockMonthly = '';
  authenticityToken;
  billingAddress = '';
  billingCity = '';
  billingCountry = '';
  billingZipcode = '';
  campaignId = '';
  campaignName = '';
  campaignPath = '';
  campaignSlug = '';
  campaignType = '';
  campaignerProfileImageTag = '';
  cardCvcElementCompleted = false;
  cardCvcElementError = '';
  cardElementCompleted = false;
  cardElementStep1Error = '';
  cardExpirationMonth = '';
  cardExpirationYear = '';
  claimedGiftAid = false;
  clickedPaypalSubmit = false;
  comment = '';
  costPerPerson = '';
  country = 'us';
  creditCardStep = 1;
  currentPaymentMethod = '';
  customDefaultAmounts = {};
  customDonateButtonText = '';
  customInfositeThankYouMailerTemplate = '';
  customInfositeThankYouPagePath = '';
  customBuilderThankYouPagePath = '';
  customBuilderWpsThankYouPagePath = '';
  customInfositeOneTimeThankYouPageName = '';
  customInfositeMonthlyThankYouPageName = '';
  customInfositeWpsThankYouPageName = '';
  customNsIntegrationCodeFor1xDonations = '';
  customNsIntegrationCodeForWps1xDonations = '';
  customNsIntegrationCodeForSubscriptionDonations = '';
  customXeroIntegrationCodeFor1xDonations = '';
  customXeroIntegrationCodeForWps1xDonations = '';
  customXeroIntegrationCodeForSubscriptionDonations = '';
  defaultSubscriptionPaymentMethodOnDesktopToBank = false;
  defaultSubscriptionPaymentMethodOnMobileToBank = false;
  disableEditAmount = false;
  disableFormSubmit = true;
  disableHeader = false;
  disableMembershipCheck = false;
  disablePaypal = false;
  disablePhoneNumber = false;
  displayName = '';
  donationFormId = '';
  email = '';
  embedded = false;
  endpointPaths = '';
  errorCode = '';
  existingMembershipCountry;
  genericFormError = null;
  genericError = {
    error: { message: 'Something went wrong, please try again.' },
  };
  generalDonateCampaignId;
  existingSubscriptionState;
  hasActiveGiftAid = false;
  hideWpsAmountButton = false;
  isAlreadySpringMember = false;
  isAnnual = false;
  isAnonymous = false;
  isBpsDonateForm = false;
  isFormMounted = false;
  isIho = false;
  isIndividualAccount = false;
  isMycwCampaign = false;
  isProfileDonation;
  isReferral = false;
  isSubscription;
  isUserLoggedIn = false;
  isValidEmail = true;
  isWpsAmountButtonSelected = false;
  kioskMode = false;
  landingPageCampaignTag = '';
  last4 = '';
  managementMode = false;
  minAmount = '';
  mostRecentOpenCampaign = null;
  name = '';
  onlyAcceptsIho = false;
  optimizelyParams = null;
  optimizelyPaymentTab;
  paymentGatewayToken = '';
  paymentIntentId = '';
  paymentMethodId = '';
  paymentRequestButtonError = '';
  paymentMethodToSwitchTo;
  paymentTab = 'stripe';
  phoneNumberConsentGranted = false;
  plaidAccountId = '';
  plaidEnv = '';
  plaidItemAddToken = '';
  plaidToken = '';
  productVariant = '';
  profileCampaignId = '';
  profileOwnerImageTag = '';
  profileOwnerName = '';
  queryParams = {};
  recaptchaRef;
  recaptchaV2Token;
  referer = '';
  referralSource = {};
  savePaymentMethod = false;
  selectedStripePaymentMethodCard;
  sendDefaultWelcomeSeriesEmails = true;
  sendPostDonationSpringAskEmails = true;
  setupIntentId = '';
  setupIntentSecret = '';
  showAchPopoutForm = false;
  showAmountInput = false;
  showDonateButtons = false;
  showOtherAmountInput = false;
  showSubscriptionOption = false;
  simulateStripeWebhooks = false;
  step = 'amount';
  stripeCardCvcElement;
  stripeCardCvcToken;
  stripeCardElement;
  stripeCustomerId;
  stripeSubmitViaEnterKeyHandler;
  subscriptionId;
  subscriptionMinAmount;
  supportsApplePay = false;
  supportsPaymentRequestButton = false;
  surname = '';
  teamName = '';
  title = '';
  triggerOpenPlaidModal = false;
  userCampaignDonationFullName = '';
  ukEuIp = true;
  emailConsentGranted = false;
  mailConsentGranted = false;
  askForAddress = 'standard_rules';
  wpsAmount;
  wpsMinimumAmount;
  phoneNumber = new (class {
    constructor() {
      makeAutoObservable(this);
    }

    displayValue = '';
    existingValue = '';
    hiddenFieldValue = '';
    selectedCountry = '';
  })();

  setProductMonthlyOnly = () => {
    this.isSubscription = true;
    this.showSubscriptionOption = false;
  };

  setProductOneTimeOnly = () => {
    this.isSubscription = false;
    this.showSubscriptionOption = false;
  };

  setProductOneTimeAndMonthly = () => {
    this.showSubscriptionOption = true;
  };

  setProductIho = () => {
    this.isSubscription = false;
    this.showSubscriptionOption = false;
    this.isIho = true;
    this.onlyAcceptsIho = true;
  };

  setCustomDefaultAmount = () => {
    if (typeof this.queryParams.amount !== 'undefined') {
      this.amount = this.queryParams.amount;
    } else if (this.customDefaultAmountsPresent) {
      this.amount = this.isSubscription
        ? this.customDefaultAmounts.monthly
        : this.customDefaultAmounts.oneTime;
    }
  };

  setOptimizelyAmount = () => {
    if (!this.optimizelyParams) return;

    this.amount = this.isSubscription
      ? this.optimizelyParams[this.country]['monthly']
      : this.optimizelyParams[this.country]['1x'];
  };

  setNameAndSurnameFromPayerName = (payerName) => {
    const split = payerName.trim().split(' ');
    const surname = split.pop(' ');
    const name = split.join(' ');

    this.name = name;
    this.surname = surname;
  };

  enableButtonWithDelay = () => {
    setTimeout(() => {
      if (!this.recaptchaV2Token) {
        this.disableFormSubmit = false;
      }
    }, 3000);
  };

  doRecaptchaV2Check = (recaptchaSuccessCallback, endpoint) => {
    window.recaptcha.instances[store.donateForm.formId].toggle();
    this.recaptchaSuccessCallback = recaptchaSuccessCallback;
    store.donateForm.tracking.recaptchaCheckExecutingForEndpoint = endpoint;
    this.enableButtonWithDelay();
  };

  handleRecaptchaSuccess = (token) => {
    this.recaptchaV2Token = token;

    if (this.recaptchaSuccessCallback) {
      this.recaptchaSuccessCallback(token);
      delete this.recaptchaSuccessCallback;
    }
  };

  createPlaidItemAddToken = (successCallback) => {
    $.ajax({
      url: '/api/v1/plaid/item_add_tokens',
      method: 'POST',
      data: {
        email: this.sanitizedEmail,
        recaptcha: {
          token: this.recaptchaV2Token,
        },
      },
      success: (response) => {
        pauseForSpecs(() => {
          this.plaidItemAddToken = response.item_add_token;
          if (successCallback) successCallback();
        });
      },
      error: (response) => {
        if (response.status == 418) {
          this.doRecaptchaV2Check(() => {
            pauseForSpecs(() => {
              this.createPlaidItemAddToken(successCallback);
            });
          }, '/api/v1/plaid/item_add_tokens');
        } else {
          store.donateForm.disableFormSubmit = false;
          store.donateForm.genericFormError = this.genericError.error.message;
        }
      },
    });
  };

  nonDebouncedProcessStripeDonation = (
    successCallback,
    errorCallback,
    requiresActionCallback
  ) => {
    $.ajax({
      url: store.donateForm.endpointPaths.stripe,
      method: 'POST',
      data: {
        ...{},
        ...this.paymentIntentParams,
        ...this.donationFormParams,
        ...(this.isSubscription && this.subscriptionParams),
        recaptcha: {
          token: this.recaptchaV2Token,
        },
      },
      success: (response) => {
        if (response.requiresAction) {
          if (requiresActionCallback) requiresActionCallback(response);
          return;
        }

        pauseForSpecs(() => {
          if (successCallback) successCallback(response);
        });
      },
      error: (response) => {
        if (response.status == 418) {
          this.doRecaptchaV2Check(() => {
            pauseForSpecs(() => {
              this.recaptchaResponseProcessStripeDonation(
                successCallback,
                errorCallback,
                requiresActionCallback
              );
            });
          }, store.donateForm.endpointPaths.stripe);
        } else {
          store.donateForm.disableFormSubmit = false;

          if (errorCallback) {
            errorCallback(response.responseJSON || this.genericError);
          }
        }
      },
    });
  };

  processStripeDonation = debounce(
    this.nonDebouncedProcessStripeDonation,
    2000,
    {
      leading: true,
      trailing: false,
    }
  );

  recaptchaResponseProcessStripeDonation = debounce(
    this.nonDebouncedProcessStripeDonation,
    2000,
    {
      leading: true,
      trailing: false,
    }
  );

  handlePaymentRequestError = (error) => {
    this.paymentRequestButtonError = error.message;
    this.disableFormSubmit = false;
  };

  constructor() {
    makeAutoObservable(this);
  }

  createDonationForm(paymentGateway, successCallback, errorCallback) {
    if (paymentGateway == 'stripe' && !this.managementMode) return;

    $.ajax({
      url: store.donateForm.endpointPaths[paymentGateway],
      method: 'POST',
      data: this.donationFormParams,
      success: (response) => {
        this.donationFormId = response.donationFormId;

        if (paymentGateway == 'stripe' && this.simulateStripeWebhooks) {
          $.post('/api/v1/stripe/simulated_stripe_events', {
            country: this.country,
            payment_id: this.setupIntentId,
          });
        }

        if (successCallback) successCallback(response);
      },
      error: (response) => {
        if (response.status == 418) {
          if (paymentGateway == 'paypal') {
            store.donateForm.clickedPaypalSubmit = false;
          }
          this.doRecaptchaV2Check(() => {
            if (paymentGateway == 'paypal') {
              store.donateForm.clickedPaypalSubmit = true;
            }
            this.createDonationForm(
              paymentGateway,
              successCallback,
              errorCallback
            );
          }, store.donateForm.endpointPaths[paymentGateway]);
        } else {
          this.disableFormSubmit = false;
          if (errorCallback)
            errorCallback(response.responseJSON || this.genericError);
        }
      },
    });
  }

  get defaultSubscriptionPaymentTab() {
    if (this.queryParams.updatePaymentError) {
      return;
    }
    if (
      (window.isMobile &&
        this.defaultSubscriptionPaymentMethodOnMobileToBank) ||
      (!window.isMobile && this.defaultSubscriptionPaymentMethodOnDesktopToBank)
    ) {
      return this.country === 'uk' ? 'bacs' : 'plaid';
    }
    return 'stripe';
  }

  setSelectedPaymentTab() {
    if (this.managementMode) {
      this.paymentTab =
        this.paymentMethodToSwitchTo ||
        this.defaultSubscriptionPaymentTab ||
        this.currentPaymentMethod;
      return;
    }

    if (this.isSubscription) {
      if (this.optimizelyPaymentTab) {
        this.paymentTab = this.optimizelyPaymentTab;
      } else {
        this.paymentTab = this.defaultSubscriptionPaymentTab;
      }
    } else {
      this.paymentTab = 'stripe';
    }
    window.loggedDefaultPaymentGatewayForSystemSpecs = this.paymentTab;
  }

  get anonymous() {
    if (
      (this.isProfileDonation ||
        this.profileCampaignId ||
        !this.isSystemCampaign) &&
      (this.displayName == '' || typeof this.displayName === 'undefined')
    ) {
      return true;
    } else {
      return this.isAnonymous;
    }
  }

  get donationFormParams() {
    if (this.managementMode) {
      return {
        account_id: this.plaidAccountId,
        authenticity_token: this.authenticityToken,
        exp_month: this.cardExpirationMonth,
        exp_year: this.cardExpirationYear,
        gift_aid: this.giftAidAddressParams,
        last4: this.last4,
        payment_token: this.paymentGatewayToken || this.plaidToken,
        setup_intent_id: this.setupIntentId,
        zip_code: this.billingZipcode,
        name: this.name,
        surname: this.surname,
      };
    }

    const params = {
      disable_existing_subscription_check: this.showUsToUkConversionMessage,
      donation_form: {
        amount: this.amount,
        anonymous: this.anonymous,
        comment: this.comment,
        display_name: this.displayName,
        email: this.sanitizedEmail,
        name: this.name,
        payment_gateway_token: this.paymentGatewayToken,
        payment_monthly_subscription: this.isSubscription,
        surname: this.surname,
        campaign_id: this.systemOrUserCampaignId,
        setup_intent_id: this.setupIntentId,
        subscription_period: this.subscriptionPeriod,
        profile_campaign_id: this.profileCampaignId,
        metadata: {
          claimed_gift_aid: this.claimedGiftAid,
          custom_infosite_thank_you_page_path:
            this.customInfositeThankYouPagePath,
          custom_builder_thank_you_page_path:
            this.customBuilderThankYouPagePath,
          custom_builder_wps_thank_you_page_path:
            this.customBuilderWpsThankYouPagePath,
          custom_infosite_one_time_thank_you_page_name:
            this.customInfositeOneTimeThankYouPageName,
          custom_infosite_monthly_thank_you_page_name:
            this.customInfositeMonthlyThankYouPageName,
          custom_infosite_wps_thank_you_page_name:
            this.customInfositeWpsThankYouPageName,
          custom_thank_you_journey_id: this.customThankYouJourneyId,
          custom_welcome_journey_id: this.customWelcomeJourneyId,
          experiments: experiments(),
          email_consent_granted: this.emailConsentGranted,
          mail_consent_granted: this.mailConsentGranted,
          event_kiosk_mode: this.eventKioskMode,
          full_donate_page_url: window.location.href,
          landing_page_campaign_tag: this.landingPageCampaignTag,
          phone_number:
            this.phoneNumber.existingValue || this.phoneNumber.hiddenFieldValue,
          phone_number_consent_granted: this.phoneNumberConsentGranted,
          plaid_account_id: this.plaidAccountId,
          plaid_public_token: this.plaidToken,
          referer: this.referer,
          send_default_welcome_series_emails:
            this.sendDefaultWelcomeSeriesEmails,
          uk_eu_ip: this.ukEuIp,
          url_params: urlParamsMetadataFields(),
          session_url_params: sessionUrlParamsMetadataFields(),
          with_saved_payment: !!this.selectedStripePaymentMethodCard,
        },
        address: {
          address_line_1: this.billingAddress,
          address_line_2: '',
          city: this.billingCity,
          country: this.billingCountry,
          zip: this.billingZipcode,
        },
      },
      recaptcha: {
        token: this.recaptchaV2Token,
      },
    };

    if (this.isMycwCampaign) {
      params['donation_form']['metadata']['mycw_campaign_id'] = this.campaignId;
    }

    if (this.acceptsIho) {
      params['donation_form']['iho_attributes'] = store.iho.donationFormParams;
    }

    if (this.isReferral) {
      params['donation_form']['metadata']['referral'] = {
        source_id: this.referralSource.sourceId,
        source_mechanism: this.referralSourceMechanism,
        source_type: this.referralSource.sourceType,
        is_profile_donation: this.isProfileDonation,
      };
    }

    if (this.customNsIntegrationCodeFor1xDonations) {
      params['donation_form']['metadata'][
        'custom_ns_integration_code_for_1x_donations'
      ] = this.customNsIntegrationCodeFor1xDonations;
      params['donation_form']['metadata'][
        'custom_ns_integration_code_for_wps_1x_donations'
      ] = this.customNsIntegrationCodeForWps1xDonations;
    }

    if (this.customNsIntegrationCodeForSubscriptionDonations) {
      params['donation_form']['metadata'][
        'custom_ns_integration_code_for_subscription_donations'
      ] = this.customNsIntegrationCodeForSubscriptionDonations;
    }

    if (this.customXeroIntegrationCodeFor1xDonations) {
      params['donation_form']['metadata'][
        'custom_xero_integration_code_for_1x_donations'
      ] = this.customXeroIntegrationCodeFor1xDonations;
      params['donation_form']['metadata'][
        'custom_xero_integration_code_for_wps_1x_donations'
      ] = this.customXeroIntegrationCodeForWps1xDonations;
    }

    if (this.customXeroIntegrationCodeForSubscriptionDonations) {
      params['donation_form']['metadata'][
        'custom_xero_integration_code_for_subscription_donations'
      ] = this.customXeroIntegrationCodeForSubscriptionDonations;
    }

    params['donation_form']['gift_aid'] = this.giftAidAddressParams;

    return params;
  }

  get referralSourceMechanism() {
    if (!this.referralSource) {
      return false;
    }
    if (this.isSubscription) {
      return this.convert1xSourceMechanismToSubscription();
    } else {
      return this.referralSource.sourceMechanism;
    }
  }

  convert1xSourceMechanismToSubscription = () => {
    switch (this.referralSource.sourceMechanism) {
      case 'mycw_campaign_donation':
        return 'mycw_campaign_subscription';
      case 'mycw_team_campaign_donation':
        return 'mycw_team_campaign_subscription';
      default:
        return this.referralSource.sourceMechanism;
    }
  };

  get systemOrUserCampaignId() {
    if (
      this.isSubscription &&
      [
        'mycw_campaign_subscription',
        'mycw_team_campaign_subscription',
      ].includes(this.referralSourceMechanism)
    ) {
      return this.generalDonateCampaignId;
    }
    return this.campaignId;
  }

  get isProfileOrCampaignDonation() {
    return (
      this.isProfileDonation || this.profileCampaignId || !this.isSystemCampaign
    );
  }

  get giftAidAddressParams() {
    if (!this.claimedGiftAid) return {};

    return {
      house_name_or_number: this.billingAddress,
      postal_code: this.billingZipcode,
    };
  }

  get hasCvcErrorCode() {
    return (
      (this.errorCode && this.errorCode.includes('cvc')) ||
      this.cardCvcElementError
    );
  }

  handleCardError = (error) => {
    if (!error.message) {
      return;
    }
    const errorMessage = error.message.replace('.', '');
    this.errorCode = error.code || null;

    if (this.selectedStripePaymentMethodCard) {
      this.cardCvcElementError = errorMessage;
    } else {
      this.cardElementStep1Error = errorMessage;
      this.creditCardStep = 1;
      this.tracking.trackCardError('stripe', errorMessage, 'step 1');
    }
    this.step = 'payment';

    this.disableFormSubmit = false;
  };

  nonDebouncedCreateSetupIntent = (successCallback) => {
    $.ajax({
      url: '/api/v1/stripe/setup_intents',
      method: 'POST',
      data: this.setupIntentParams,
      success: (response) => {
        this.setupIntentId = response.id;
        this.setupIntentSecret = response.setup_intent_secret;

        if (successCallback) successCallback();
      },
      error: (response) => {
        if (response.status == 418) {
          this.doRecaptchaV2Check(() => {
            this.recaptchaResponseCreateSetupIntent(successCallback);
          }, '/api/v1/stripe/setup_intents');
        } else {
          store.donateForm.disableFormSubmit = false;
        }
      },
    });
  };

  createSetupIntent = debounce(this.nonDebouncedCreateSetupIntent, 2000, {
    leading: true,
    trailing: false,
  });

  recaptchaResponseCreateSetupIntent = debounce(
    this.nonDebouncedCreateSetupIntent,
    2000,
    {
      leading: true,
      trailing: false,
    }
  );

  getStripePaymentMethodCards = () => {
    $.ajax({
      url: '/api/v1/stripe/saved_payments',
      method: 'GET',
      data: { country: this.country },
      success: (response) => {
        this.stripePaymentMethodCards = response.stripe_payment_method_cards;
        this.selectedStripePaymentMethodCard =
          response.stripe_payment_method_cards[0];
        this.stripeCustomerId = response.stripe_customer_id;
        window.loggedSavedPaymentMethodDisplayedForSystemSpecs = !isEmpty(
          this.stripePaymentMethodCards
        );
      },
    });
  };

  get setupIntentParams() {
    return {
      country: this.country,
      setup_intent: {
        email: this.sanitizedEmail,
      },
      recaptcha: {
        token: this.recaptchaV2Token,
      },
    };
  }

  get confirmSetupIntentParams() {
    return {
      payment_method: this.paymentMethodId,
      ...(this.cvcVerificationRequired && {
        payment_method_options: { card: { cvc: this.stripeCardCvcElement } },
      }),
    };
  }

  get subscriptionParams() {
    return {
      subscription: {
        amount: this.amount,
        country: this.country,
        email: this.sanitizedEmail,
        full_name: this.fullName,
        is_annual: this.isAnnual,
      },
    };
  }

  get paymentIntentParams() {
    if (this.paymentIntentId) {
      return {
        country: this.country,
        payment_intent_id: this.paymentIntentId,
        donation_form: {
          email: this.sanitizedEmail,
        },
        payment_intent: {
          payment_method: this.paymentMethodId,
        },
      };
    }

    return {
      country: this.country,
      payment_intent: {
        email: this.sanitizedEmail,
        amount: this.amount,
        currency: this.currencyName,
        metadata: {},
        payment_method: this.paymentMethodId,
        ...(this.cvcVerificationRequired && {
          payment_method_options: {
            card: { cvc_token: this.stripeCardCvcToken },
          },
        }),
        ...(!isEmpty(this.stripeCustomerId) && {
          stripe_customer_id: this.stripeCustomerId,
        }),
        ...((this.savePaymentMethod || this.isSubscription) &&
          !this.managementMode && {
            setup_future_usage: 'off_session',
          }),
      },
      recaptcha: {
        token: this.recaptchaV2Token,
      },
    };
  }

  get paymentMethodParams() {
    return {
      card: this.stripeCardElement,
      billing_details: {
        address: {
          ...(this.shouldIncludeZipcode && {
            postal_code: this.billingZipcode,
          }),
          city: this.billingCity,
          country: this.defaultOrSelectedBillingCountry,
          line1: this.billingAddress,
        },
        email: this.sanitizedEmail,
        name: this.fullName,
      },
      type: 'card',
    };
  }

  get cvcVerificationRequired() {
    return this.selectedStripePaymentMethodCard && !this.isSubscription;
  }

  get shouldIncludeZipcode() {
    return (
      store.donateForm.defaultOrSelectedBillingCountry === 'US' ||
      store.donateForm.defaultOrSelectedBillingCountry === 'GB'
    );
  }

  get formId() {
    return `${this.campaignSlug}_form`;
  }

  get acceptsIho() {
    return this.isIho && !this.isSubscription;
  }

  get defaultOrSelectedBillingCountry() {
    if (this.billingCountry) return this.billingCountry;

    return this.accountCountry;
  }

  get accountCountry() {
    return {
      us: 'US',
      uk: 'GB',
    }[this.country];
  }

  get currencyUnit() {
    return {
      us: '$',
      uk: '£',
    }[this.country];
  }

  get currencyName() {
    return {
      us: 'usd',
      uk: 'gbp',
    }[this.country];
  }

  get locale() {
    return {
      us: 'en',
      uk: 'en-GB',
    }[this.country];
  }

  get giftAidAmount() {
    return amountWithPrecisionAsRequiredAndComma(this.amount * 0.25);
  }

  get isCalmMode() {
    return this.queryParams.calmMode === 'true';
  }

  get donateButtonText() {
    if (this.customDonateButtonText) return this.customDonateButtonText;

    if (this.isCalmMode) {
      return `Give ${this.currencyUnit}${amountWithPrecisionAsRequired(
        this.amount
      )}`;
    }

    let subscriptionPeriodText = '';

    if (this.isSubscription) {
      subscriptionPeriodText = this.isAnnual ? ' annually' : ' monthly';
    }

    return `Donate ${this.currencyUnit}${amountWithPrecisionAsRequiredAndComma(
      this.amount
    )}${subscriptionPeriodText}`;
  }

  get showAddressCollection() {
    if (!this.isCalmMode) {
      return false;
    } else if (this.country == 'uk') {
      return (
        this.claimedGiftAid ||
        (!this.isSubscription && this.amount >= 750) ||
        (this.isSubscription && this.amount >= 75)
      );
    } else {
      return (
        (!this.isSubscription && this.amount >= 1000) ||
        (this.isSubscription && this.amount >= 100)
      );
    }
  }

  get showPhoneNumberField() {
    return (
      this.country == 'uk' &&
      (!this.isUserLoggedIn ||
        (this.isUserLoggedIn &&
          !this.phoneNumber.existingValue &&
          !this.disablePhoneNumber))
    );
  }

  get showAddressStep() {
    if (
      this.isCalmMode ||
      (this.selectedStripePaymentMethodCard && !this.claimedGiftAid) ||
      this.kioskMode ||
      this.eventKioskMode ||
      this.disableAddressCollection
    )
      return false;

    return true;
  }

  get showSubscriptionToggle() {
    return (
      this.showSubscriptionOption &&
      (!this.isAlreadySpringMember ||
        this.existingSkippedOrFailedPaymentSubscription)
    );
  }

  get showSubscriptionSavedPaymentMessage() {
    return (
      this.step === 'payment' &&
      this.isSubscription &&
      (this.paymentTab == 'bacs' ||
        (this.paymentTab == 'stripe' &&
          this.creditCardStep == 1 &&
          !this.selectedStripePaymentMethodCard))
    );
  }

  get showSpringMembershipAlreadyExistsMessage() {
    return (
      this.isSubscription &&
      this.isAlreadySpringMember &&
      !this.disableMembershipCheck &&
      !this.existingSkippedOrFailedPaymentSubscription
    );
  }

  get showUsToUkConversionMessage() {
    return (
      this.isSubscription &&
      this.country.toLowerCase() == 'uk' &&
      this.existingMembershipCountry.toLowerCase() == 'us'
    );
  }

  get isPaypalAvailable() {
    if (this.country == 'uk') return false;
    if (this.disablePaypal) return false;
    return true;
  }

  get fullName() {
    return `${this.name} ${this.surname}`;
  }

  get isSystemCampaign() {
    return this.campaignType == 'system';
  }

  get customDefaultAmountsPresent() {
    return (
      store.donateForm.customDefaultAmounts &&
      typeof store.donateForm.customDefaultAmounts.oneTime != 'undefined' &&
      typeof store.donateForm.customDefaultAmounts.monthly != 'undefined'
    );
  }

  get showAmountStepTextBlock() {
    return (
      !this.isWpsAmountButtonSelected &&
      (this.amountStepTextBlock1xDonation || this.isSubscription)
    );
  }

  get customAmountStepText() {
    if (this.amountStepTextBlockMonthly && this.isSubscription) {
      return this.amountStepTextBlockMonthly;
    }

    if (this.amountStepTextBlock1xDonation && !this.isSubscription) {
      return this.amountStepTextBlock1xDonation;
    }
  }

  get amountButtonSubscriptionPeriod() {
    if (this.isSubscription) {
      return this.isAnnual ? '/yr' : '/mo';
    } else {
      return '';
    }
  }

  get amountInputSubscriptionPeriod() {
    return amountInputSubscriptionPeriodOption(
      this.isSubscription,
      this.isAnnual
    );
  }

  get subscriptionPeriod() {
    if (this.isSubscription) {
      return this.isAnnual ? 'annual' : 'monthly';
    } else {
      return '';
    }
  }

  get minimumAmount() {
    return minimumAmountOption({
      isSubscription: this.isSubscription,
      minAmount: this.minAmount,
      customSubscriptionMinAmount: this.subscriptionMinAmount,
    });
  }

  get existingSkippedOrFailedPaymentSubscription() {
    return (
      this.isAlreadySpringMember &&
      ['failed_payment', 'skipped'].includes(this.existingSubscriptionState)
    );
  }

  get sanitizedEmail() {
    return this.email.toLowerCase();
  }

  get frequency() {
    if (this.isSubscription) {
      return this.isAnnual ? 'annual' : 'monthly';
    } else {
      return '1x';
    }
  }

  subscriptionUpsell = new (class {
    constructor() {
      makeAutoObservable(this);
    }

    _originalAmount;
    _originalPeriod;
    isOptedIn = false;
    sortedBrackets;
    sortedBracketsMonthlyToAnnual;
    wasDisplayed = false;

    get originalAmount() {
      return this._originalAmount || store.donateForm.amount;
    }

    set originalAmount(amount) {
      this._originalAmount = amount;
    }

    get originalPeriod() {
      return this._originalPeriod || store.donateForm.frequency;
    }

    set originalPeriod(period) {
      this._originalPeriod = period;
    }

    amount = () =>
      Math.floor((this.originalAmount * this.matchingBracket().percent) / 100);

    matchingBracket = () => {
      const brackets =
        this.originalPeriod == 'monthly'
          ? this.sortedBracketsMonthlyToAnnual
          : this.sortedBrackets;

      return find(
        brackets,
        (bracket) => bracket.minAmount <= this.originalAmount
      );
    };

    matchingBracketIsValid = () => {
      const bracket = this.matchingBracket();

      if (
        typeof bracket.minAmount === 'undefined' ||
        typeof bracket.period === 'undefined' ||
        typeof bracket.percent === 'undefined' ||
        !includes(['monthly', 'annual'], bracket.period) ||
        isNaN(parseFloat(bracket.minAmount)) ||
        isNaN(parseFloat(bracket.percent))
      ) {
        return false;
      }

      return true;
    };

    showUpsell = () => {
      const shouldShowUpsell =
        !store.donateForm.isAlreadySpringMember &&
        !store.iho.honoreeInfoIsPresent &&
        store.donateForm.amount >= store.donateForm.subscriptionMinAmount &&
        store.donateForm.amount < store.donateForm.wpsMinimumAmount &&
        this.matchingBracket() &&
        !this.matchingBracket().disableUpsell &&
        this.matchingBracketIsValid();
      store.donateForm.subscriptionUpsell.wasDisplayed = !!shouldShowUpsell;
      return shouldShowUpsell;
    };

    toggleIsOptedIn = (period, isOptedIn) => {
      if (isOptedIn) {
        this.originalAmount = store.donateForm.amount;
        this.originalPeriod = store.donateForm.frequency;

        store.donateForm.isSubscription = true;
        store.donateForm.amount = this.amount();
      } else {
        store.donateForm.isSubscription = this.originalPeriod === 'monthly';
        store.donateForm.amount = this.originalAmount;
        this.originalAmount = null;
      }

      store.donateForm.setSelectedPaymentTab();
      this.isOptedIn = isOptedIn;

      if (period === 'annual') {
        store.donateForm.disablePaypal = isOptedIn;
        store.donateForm.isAnnual = isOptedIn;
      }
    };
  })();

  tracking = new (class {
    constructor() {
      makeAutoObservable(this, { eventProperties: false });
    }

    interactionStartedEventTracked = false;
    recaptchaCheckExecutingForEndpoint = 'unknown';
    eventName = '';
    eventProperties = {};

    get subscriptionUpsellProperties() {
      return {
        original_amount: store.donateForm.subscriptionUpsell.originalAmount,
        original_period: store.donateForm.subscriptionUpsell.originalPeriod,
        upsell_amount: store.donateForm.subscriptionUpsell.amount(),
        upsell_period:
          store.donateForm.subscriptionUpsell.matchingBracket().period,
      };
    }

    get checkoutStartedProperties() {
      return {
        other_amount_input_used: store.donateForm.showOtherAmountInput,
        default_amount_input_used: !store.donateForm.showOtherAmountInput,
        currency: store.donateForm.currencyName,
        default_payment_gateway: store.donateForm.paymentTab,
        pre_upsell_amount: Number(store.donateForm.amount),
        saved_payment_method_displayed: !isEmpty(
          store.donateForm.stripePaymentMethodCards
        ),
      };
    }

    get contactInfoProvidedProperties() {
      return {
        country: store.donateForm.country,
        email: store.donateForm.email,
        is_subscription: store.donateForm.isSubscription,
        phoneNumber:
          store.donateForm.phoneNumber.existingValue ||
          store.donateForm.phoneNumber.hiddenFieldValue,
        phone_number_consent_granted:
          store.donateForm.phoneNumberConsentGranted,
      };
    }

    get donationSubmittedProperties() {
      let upsellProperties = {};
      const matchingBracket =
        store.donateForm.subscriptionUpsell.matchingBracket();

      if (matchingBracket) {
        upsellProperties = {
          upsell_period: matchingBracket.period,
          upsell_percent: matchingBracket.percent,
        };
      }

      return Object.assign(
        {
          payment_gateway: store.donateForm.paymentTab,
          revenue: Number(store.donateForm.amount),
          period: store.donateForm.frequency,
          is_wps:
            Number(store.donateForm.amount) >=
            store.donateForm.wpsMinimumAmount,
          saved_payment_method_added:
            store.donateForm.paymentTab == 'stripe' &&
            store.donateForm.savePaymentMethod,
          existing_saved_payment_method_used:
            store.donateForm.paymentTab == 'stripe' &&
            !!store.donateForm.selectedStripePaymentMethodCard,
          upsell_displayed: store.donateForm.subscriptionUpsell.wasDisplayed,
        },
        upsellProperties
      );
    }

    get trackingEventProperties() {
      switch (this.eventName) {
        case 'Donation Form Interaction Started':
          return {};
        case 'Upsell Viewed':
        case 'Upsell Accepted':
        case 'Upsell Declined':
          return this.subscriptionUpsellProperties;
        case 'Checkout Started':
          this.eventProperties = this.checkoutStartedProperties;
          return this.eventProperties;
        case 'Donation Form Submitted':
          return {
            ...this.eventProperties,
            ...this.donationSubmittedProperties,
          };
      }
    }

    trackDonateEvent = (eventName) => {
      this.eventName = eventName;

      window.track_event(this.eventName, this.trackingEventProperties);
    };

    trackDonationFormSubmittedEvent = () => {
      this.trackDonateEvent('Donation Form Submitted');
    };

    trackCheckoutStartedEvent = () => {
      this.trackDonateEvent('Checkout Started');
    };

    trackContactInfoProvidedEvent = () => {
      $.post('/api/v1/iterable/event/track', {
        event: 'contactInfoProvided',
        properties: this.contactInfoProvidedProperties,
      });
    };

    trackInteractionStartedEvent = (e) => {
      if (['A', 'BUTTON', 'INPUT', 'SPAN'].includes(e.target.tagName)) {
        store.donateForm.tracking.interactionStartedEventTracked = true;
        this.trackDonateEvent('Donation Form Interaction Started');
      }
    };

    trackCardError = (paymentGateway, error, errorLocation) => {
      window.track_event('Card Error', {
        error_location: errorLocation,
        error_message: error,
        error_type: 'error_message_shown_to_user',
        management_mode: store.donateForm.managementMode,
        payment_gateway: paymentGateway,
        subscription: store.donateForm.isSubscription,
        subscription_id: store.donateForm.subscriptionId,
        with_saved_payment: !!store.donateForm.selectedStripePaymentMethodCard,
      });
    };
  })();

  get editAmountDisabled() {
    return this.disableEditAmount || this.disableFormSubmit;
  }

  get amount() {
    return this._amount;
  }

  set amount(amount) {
    this.dispatchAmountChangedEvent(amount);
    this._amount = amount;
  }

  dispatchAmountChangedEvent = (amount) => {
    window.dispatchEvent(
      new CustomEvent('donate form amount changed', {
        detail: {
          amount: amount,
          currency_formatted_amount: `${
            this.currencyUnit
          }${amountWithPrecisionAsRequiredAndComma(amount)}`,
          frequency: this.frequency,
        },
      })
    );
  };

  dispatchStepChangedEvent = (step) => {
    const properties = {
      step: step,
    };

    if (step === 'subscription-upsell') {
      properties.original_frequency =
        this.tracking.subscriptionUpsellProperties.original_period;
      properties.upsell_frequency =
        this.tracking.subscriptionUpsellProperties.upsell_period;
    }

    window.dispatchEvent(
      new CustomEvent('donate form step changed', {
        detail: properties,
      })
    );
  };

  get isMycwSubscription() {
    return this.isMycwCampaign && this.isSubscription;
  }
}
